import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import Seo from '../../components/seo'

import Pager from '../../components/pager.js'
import ReactMarkdown from 'react-markdown'
import { getDateFormatted } from '../../helpers/getDateFormatted'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import pictureFeedSchedule from '../../images/picture-schedule.jpg'
import Carousels from '../../components/carousel'
import { useForm } from '../../hooks/useForm'
import urlSlug from 'url-slug'
import { findNDiarieCategoryExist } from '../../helpers/findDiarieCategoryExist'
import { DIARY_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'

const eventList = ({ data, pageContext }) => {
  const { diariesCategoriesData } = pageContext
  const { strapiOrganizationalUnit: organizationalUnit } = data

  const objCarousel = {
    visible: true,
    slide: organizationalUnit.mainCover?.diariesCover
  }

  const isHomeUcc = false
  const [valuesFilter, handleInputChange] = useForm({
    categorySelected: 'Todas'
  })
  const { categorySelected } = valuesFilter
  const [invalidCategory, setInvalidCategory] = useState(false)

  useEffect(() => {
    if (categorySelected !== 'Todas') {
      setInvalidCategory(() =>
        findNDiarieCategoryExist(categorySelected, data.allStrapiDiary.nodes)
      )
      if (!invalidCategory !== true) {
        navigate(`${urlSlug(categorySelected)}/`, {
          replace: false
        })
      }
    }
  }, [categorySelected, invalidCategory])

  const handleClickViewAllUcc = (e) => {
    e.preventDefault()
    navigate(`/${DIARY_GENERAL_ROUTE}/`, {
      replace: false
    })
  }

  return (
    <Layout page={organizationalUnit} logo={organizationalUnit.logo}>
      <Seo title={`Agenda ${organizationalUnit.name}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0 ? 
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'scheduleFeed-'} />
          </div>
        : null}
        <div className="container">
          <div className="my-5">
            <div className="row d-flex align-items-center justify-content-center mb-4">
              <div className="col-12 col-md-8  mb-auto">
                <h5 className=" text-uppercase title-secondary text-justify">
                  Agenda{' '}
                  <span className="font-weight-bold text-main">
                    {organizationalUnit.name}
                  </span>
                </h5>
              </div>
              <form className="col-12 col-md-4">
                <div className="d-flex  flex-md-column feed-form">
                  <select
                    className=" form-control form-control-sm"
                    onChange={handleInputChange}
                    name="categorySelected"
                    defaultValue="-1"
                  >
                    <option value="-1" disabled>
                      Por Categoría
                    </option>
                    <option value="Todas">Todas</option>
                    {diariesCategoriesData.map((category) => (
                      <option value={category.name} key={category.strapiId}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <div className="ml-auto">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleClickViewAllUcc}
                    >
                      Toda la agenda UCC
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              {data.allStrapiDiary.nodes.length === 0 ? (
                <h4 className="mt-5 col">
                  No se encontraron agendas pertenecientes a{' '}
                  {`"${organizationalUnit.name}"`}
                </h4>
              ) : !invalidCategory !== false && categorySelected !== 'Todas' ? (
                <h4 className="mt-5 col">
                  {' '}
                  No se encontraron agendas de {`"${organizationalUnit.name}"`}{' '}
                  con la categoría
                  {` "${categorySelected}"`}
                </h4>
              ) : (
                data.allStrapiDiary.nodes.map((diary) => {
                  const objDate = getDateFormatted(new Date(diary.date))
                  return (
                    <div
                      className={
                        'col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4 mb-3 d-flex align-items-stretch'
                      }
                      key={diary.strapiId}
                    >
                      <div
                        key={`diaresId-${diary.strapiId}`}
                        className={
                          'schedule-card-wrapper mx-2 p-4 h-100 rounded shadow-sm bg-white'
                        }
                        style={{ minHeight: 292 + 'px' }}
                      >
                        <Link
                          to={`/${DIARY_GENERAL_ROUTE}/${diary.slug}`}
                          className="text-decoration-none"
                        >
                          <div className="text-center text-uppercase d-flex flex-column align-items-center justify-content-center py-2 border-bottom">
                            <h6
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            >
                              {objDate.day}
                            </h6>
                            <h1
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            >
                              {objDate.numberDay}
                            </h1>
                            <h6
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            >
                              {objDate.mounth}
                            </h6>
                          </div>
                          <div className="h-50 text-uppercase d-flex flex-column align-items-stretch justify-content-between pt-2">
                            <ReactMarkdown
                              escapeHtml={false}
                              source={diary.title}
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            />
                            <h6>
                              <FontAwesomeIcon
                                icon={['fas', 'map-marker-alt']}
                                size="lg"
                                className={`mr-1 ${
                                  isHomeUcc ? 'ucc-text-blue' : ''
                                }`}
                              />
                              <span
                                className={`font-weight-bold text-capitalize ${
                                  isHomeUcc ? 'ucc-text-blue' : ''
                                }`}
                              >
                                {diary.place}
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default eventList

export const newListQueryOU = graphql`
  query($skip: Int, $limit: Int, $date: Date, $orgUnitId: Int) {
    allStrapiDiary(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: ASC }
      filter: {
        date: { gte: $date }
        organizational_unit: { id: { eq: $orgUnitId } }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        date(formatString: "YYYY/MM/DD", locale: "es")
        time
        diary_category {
          name
        }
      }
    }
    strapiOrganizationalUnit(strapiId: { eq: $orgUnitId }) {
      name
      logo {
        url
        image {
          url
        }
      }
      navbar {
        name
        icon {
          code
          type
        }
        careersList {
          name
          ucc_careers {
            name
            slug
          }
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            name
            slug
          }
        }
        organizational_unit {
          name
          slug
        }
        page {
          name
          slug
        }
        pages {
          name
          icon {
            code
            type
          }
          page {
            name
            slug
          }
        }
      }
      mainCover {
        diariesCover {
          visible
          url
          name
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
